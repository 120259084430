@use 'Colors.module.scss';

@mixin standardBtn($bgcolor, $fgcolor) {
  background: $bgcolor;
  color: $fgcolor;
  border-radius: 99px;
  height: 44px;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 16px;

  &:hover {
    color: $fgcolor !important;
  }
}

.cardBtn {
  background-color: Colors.$gray-light;
  color: Colors.$slate;
  border-radius: 99px;
  width: 110px;
  height: 44px;
  padding: 6px 16px;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 16px;

  &:disabled a {
    color: Colors.$gray-dark;
  }
}

.midnightBtn {
  @include standardBtn(Colors.$midnight, Colors.$white);
}

.twilightBtn {
  @include standardBtn(Colors.$twilight, Colors.$white);
}

.blueBtn {
  @include standardBtn(Colors.$blue, Colors.$white);
}

.greenBtn {
  @include standardBtn(Colors.$green, Colors.$white);
}

.yellowBtn {
  @include standardBtn(Colors.$yellow, Colors.$white);
}

.redBtn {
  @include standardBtn(Colors.$red, Colors.$white);
}

.grayBtn {
  @include standardBtn(Colors.$gray-light, Colors.$slate);
}

.whiteBtn {
  @include standardBtn(Colors.$white, Colors.$slate);
}

.enabledGreenBtn {
  @extend .greenBtn;
  width: 90px;
  height: 44px;
  justify-self: end;
  border-radius: 99px !important;
}

.previewBtn {
  @extend .midnightBtn;
  height: 40px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}

.saveBtn {
  @extend .blueBtn;
  width: 120px;
  position: fixed;
  bottom: 0;
  right: 0;
  margin-bottom: 24px;
  margin-right: 24px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 15px;
}

.disabledBtn {
  background: Colors.$gray-dark !important;
  color: Colors.$white;
  justify-self: end;
  font-weight: 600;
  border-radius: 99px !important;
  opacity: 1 !important;

  &:hover {
    cursor: not-allowed;
  }
}

.disabledGreenBtn {
  background: rgba(37, 201, 85, 0.5);
  color: Colors.$white;
  width: 90px;
  height: 44px;
  justify-self: end;
  font-weight: 600;
  border-radius: 99px !important;

  &:hover {
    cursor: not-allowed;
  }
}

.selectedToggleBtn,
.selectedToggleBtn:hover {
  background-color: Colors.$blue;
  color: Colors.$white;
  text-transform: none;
}

@media (max-width: 600px) {
  .cardBtn,
  .standardBtn {
    width: 100%;
  }
}
