.form {
  background-color: #ffffff;
  border-radius: 1rem;
  max-width: 360px;
  padding: 2rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px; 
}
.businessRegistrationForm {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  margin: auto;
  column-gap: 16px;
}
.gridHeader {
  grid-column: span 2;
}
.gridFooter {
  grid-column: span 2;
}
.link:hover {
  color: #0250DD;
  cursor: pointer;
}
.error {
  color: #E85757;
  font-size: 14px;
  margin-bottom: 0;
  margin-left: 5px;
  margin-top: 5px;
}

.checkbox {
  margin-bottom: 1rem !important;
  display: flex;
  flex-direction: row;
  font-family: "Montserrat", "Helvetica", "Arial", "sans-serif";
}
.link:hover,
.loginBtn:hover,
.checkbox:hover {
  cursor: pointer;
}

.modal {
  height: auto;
  top: 25%;
}
.modalForm {
  padding: 30px;
}
.ccBottomText {
  margin-top: 0.75rem;
  font-size: 0.875rem;
  display: flex;
  justify-content: center;
  font-style: italic;
}
